import { useAuth } from 'oidc-react';
import Button from '@mui/material/Button';
import {
  Info,
  Groups,
  Category,
  GitHub,
  Description,
  Settings,
} from '@mui/icons-material';
import {
  Card,
  LinearProgress,
  TableRow,
  Chip,
  Tab,
  Tabs,
  Box,
  TableBody,
  TableCell,
  Table,
  Typography,
} from '@mui/material';
import React from 'react';
import { timeAgo } from '../shared/data-utils';
import { renderPerson } from '../shared/serviceHandler';
import { useParams } from 'react-router-dom';
import CampaignsTable from '../components/CampaignsTable';
import useCampaign from '../hooks/useCampaign';
import useFindings from '../hooks/useFindings';
import { findings_columns } from '../shared/campaigns';
import Config from '../shared/Config';
import axios from 'axios';
import { handlerApiError } from '../shared/errorHandler';
import { isCampaignsAdmin } from '../shared/serviceHandler';
const { apiBaseUrl } = Config();

export default function CampaignPage(params) {
  const auth = useAuth();
  const { campaignname } = useParams();
  const { campaign, loading, person, p_loading } = useCampaign(campaignname);
  const [searchcampaign, setSearchcampaign] = React.useState([]);
  const [searchActive, setSearchActive] = React.useState(false);
  const [tab, setTab] = React.useState('open');
  //const { findings, findings_loading } = useFindings(campaignname);
  const [findings_loading, setLoading] = React.useState(true);
  const [findings, setFindings] = React.useState([]);

  const getFindings = (campaign, status) => {
    let headers = {};
    const access_token = window.sessionStorage.getItem('token');
    headers.Authorization = `Bearer ${access_token}`;
    const endpointUrl = `${apiBaseUrl}`;
    let config = {
      url: `${endpointUrl}/campaigns/${campaign}/findings?status=${status}`,
      headers,
    };
    axios(config)
      .then((response) => {
        const { data } = response;
        setFindings(data);
        setLoading(false);
      })
      .catch((err) => handlerApiError(err));
  };

  React.useEffect(() => {
    getFindings(campaignname, tab);
  }, [campaignname, tab]); // <-- empty dependency array

  /*
  if (!loading) {
    findings_columns.splice(2, 0, {
      id: 'owner_name',
      disablePadding: false,
      label: 'Owner',
      disableTruncate: true,
      resource: 'teams',
      route: true,
    });
  }
  */

  const handleInputChange = async (e) => {
    const len = e.target.value.length;
    if (e.target.value === '') {
      console.log('clear input', e.target.value);
      setSearchActive(false);
      setSearchcampaign([]);
    }
    if (len > 1) {
      setSearchActive(true);
      console.log('search:', e.target.value);
      const filtered = campaign.filter(
        (i) =>
          i.team_name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
      );
      console.log({ filtered });
      setSearchcampaign(filtered);
    }
  };

  const handleTabChange = (event, newValue) => {
    setLoading(true);
    setTab(newValue);
  };

  return (
    <span>
      {loading ? (
        <span>
          <LinearProgress />
          <h2>loading campaign ...</h2>
        </span>
      ) : (
        <span>
          <Card>
            <Typography variant="h5" component="div" sx={{ ml: '10px' }}>
              <b>{campaign.campaign.name}</b>{' '}
            </Typography>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Description</b>
                  </TableCell>
                  <TableCell>{campaign.campaign.description}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Category</b>
                  </TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      startIcon={<Category />}
                      href={`/categories/${campaign.campaign.category}`}
                    >
                      {campaign.campaign.category}
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Due Date</b>
                  </TableCell>
                  <TableCell>
                    <Chip size="small" label={campaign.campaign.due_date} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Created By</b>
                  </TableCell>
                  <TableCell>{renderPerson(person)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Owning Team</b>
                  </TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      target="_blank"
                      startIcon={<Groups />}
                      href={`https://puffdir.sre.gopuff.com/team/${campaign.campaign.owning_team}`}
                    >
                      {campaign.campaign.owning_team}
                    </Button>
                  </TableCell>
                </TableRow>
                {campaign.campaign.notification_policy && (
                  <TableRow>
                    <TableCell>
                      <b>Notification Policy</b>
                    </TableCell>
                    <TableCell>
                      {campaign.campaign.notification_policy || 'N/A'}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>
                    <b>Documentation</b>
                  </TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      target="_blank"
                      startIcon={<Description />}
                      href={`${campaign.campaign.documentation}`}
                    >
                      {campaign.campaign.documentation?.substring(
                        campaign.campaign.documentation.lastIndexOf('/') + 1
                      )}
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Source Code</b>
                  </TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      target="_blank"
                      startIcon={<GitHub />}
                      href={`${campaign.campaign.source_code}`}
                    >
                      {campaign.campaign.source_code?.substring(
                        campaign.campaign.source_code.lastIndexOf('/') + 1
                      )}
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Created At</b>
                  </TableCell>
                  <TableCell>
                    {campaign.campaign.created_at &&
                      new Date(
                        campaign.campaign.created_at
                      ).toLocaleString()}{' '}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Last checked at</b>
                  </TableCell>
                  <TableCell>
                    {campaign.campaign.checked_at &&
                      new Date(
                        campaign.campaign.checked_at
                      ).toLocaleString()}{' '}
                    ({timeAgo(campaign.campaign.checked_at)} ago)
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Typography
              variant="h5"
              component="div"
              sx={{ mt: '20px', ml: '10px' }}
            >
              <b>Findings</b>
            </Typography>

            <Tabs
              value={tab}
              onChange={handleTabChange}
              aria-label="secondary tabs example"
            >
              <Tab
                value="open"
                disabled={campaign.findings_count.open > 0 ? false : true}
                label={`Open (${campaign.findings_count.open || 0})`}
              />
              <Tab
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'table-cell',
                  },
                }}
                value="acknowledged"
                disabled={
                  campaign.findings_count.acknowledged > 0 ? false : true
                }
                label={`Acknowledged (${
                  campaign.findings_count.acknowledged || 0
                })`}
              />
              <Tab
                sx={{
                  display: {
                    xs: 'table-cell',
                    sm: 'none',
                  },
                }}
                value="acknowledged"
                disabled={
                  campaign.findings_count.acknowledged > 0 ? false : true
                }
                label={`Ack (${campaign.findings_count.acknowledged || 0})`}
              />
              <Tab
                value="fixed"
                disabled={campaign.findings_count.fixed > 0 ? false : true}
                label={`Fixed (${campaign.findings_count.fixed || 0})`}
              />
              <Tab
                value="autoresolved"
                disabled={
                  campaign.findings_count.autoresolved > 0 ? false : true
                }
                label={`Autoresolved (${
                  campaign.findings_count.autoresolved || 0
                })`}
              />
              {isCampaignsAdmin(auth) && (
                <Tab
                  sx={{ color: 'red' }}
                  value="ignored"
                  disabled={campaign.findings_count.ignored > 0 ? false : true}
                  label={`Ignored (${campaign.findings_count.ignored || 0})`}
                />
              )}
            </Tabs>
            {!findings_loading ? (
              <span>
                <CampaignsTable
                  id="campaigns"
                  rows={findings}
                  columns={findings_columns}
                />
              </span>
            ) : (
              <span>
                <LinearProgress />
              </span>
            )}
          </Card>
        </span>
      )}
    </span>
  );
}
