import React from 'react';
import {
  AccountBox,
  People,
  MiscellaneousServices,
  Groups,
  Campaign,
  Category,
  SettingsSuggest,
  Assignment,
  Home,
  Storage,
  HelpCenter,
} from '@mui/icons-material';
import {
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

function AppDrawer({ drawerOpen, setDrawerOpen }) {
  const { pathname } = useLocation();

  return (
    <Drawer
      variant="temporary"
      anchor="left"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <List>
        <ListItemButton
          component={Link}
          to="/"
          selected={pathname === '/'}
          onClick={() => setDrawerOpen(false)}
        >
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/campaigns"
          selected={pathname === '/campaigns'}
          onClick={() => setDrawerOpen(false)}
        >
          <ListItemIcon>
            <Campaign />
          </ListItemIcon>
          <ListItemText primary="All Campaigns" />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/categories"
          selected={pathname === '/categories'}
          onClick={() => setDrawerOpen(false)}
        >
          <ListItemIcon>
            <Category />
          </ListItemIcon>
          <ListItemText primary="Campain Categories" />
        </ListItemButton>
        <ListItemButton
          component="a"
          href="https://gopuff.atlassian.net/wiki/spaces/GD/pages/3237347329/Puff+Campaigns+Guide"
          target="_blank"
          rel="noopener noreferrer"
          selected={pathname === '/about'}
          onClick={() => setDrawerOpen(false)}
        >
          <ListItemIcon>
            <HelpCenter />
          </ListItemIcon>
          <ListItemText primary="About" />
        </ListItemButton>
      </List>
      <Divider />
    </Drawer>
  );
}
export default AppDrawer;
