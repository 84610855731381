import React from 'react';
import axios from 'axios';
import Config from '../shared/Config';
import { useParams } from 'react-router-dom';
import { handlerApiError } from '../shared/errorHandler';

const { apiBaseUrl } = Config();
let endpointUrl = `${apiBaseUrl}/teams`;
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const cache = {};
export default function useTeamCampaigns(teamname) {
  console.log(`useTeamCampaigns called: ${teamname}`);
  const access_token = window.sessionStorage.getItem('token');
  headers.Authorization = `Bearer ${access_token}`;
  const [loading, setLoading] = React.useState(true);
  const [campaigns, setCampaign] = React.useState({});

  const getCampaigns = (config, teamname) => {
    config.url = `${endpointUrl}/${teamname}`;
    axios(config)
      .then((response) => {
        const { data } = response;
        setCampaign(data);
        cache[teamname] = data;
        setLoading(false);
      })
      .catch((err) => handlerApiError(err));
  };

  React.useEffect(() => {
    const config = { url: endpointUrl, headers };
    if (cache[teamname]) {
      setCampaign(cache[teamname]);
      setLoading(false);
    } else {
      try {
        getCampaigns(config, teamname);
      } catch (error) {
        console.log('An error occurred:', error);
      }
    }
  }, [teamname]);

  // const updateCampaign = (campaign, json) => {
  //   const config = {
  //     url: `${endpointUrl}/${campaign.campaign_slug}`,
  //     headers,
  //     method: 'put',
  //     data: json,
  //   };
  //   return axios(config);
  // };

  return { loading, campaigns };
}
