import aox from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import MuiGrid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Card, LinearProgress, TextField, Typography } from '@mui/material';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import CampaignsTable from '../components/CampaignsTable';
import useCampaigns from '../hooks/useCampaigns';
import { campaign_columns } from '../shared/campaigns';
export default function CampaignsPage(params) {
  const { campaigns, loading } = useCampaigns();
  const [searchcampaign, setSearchcampaign] = React.useState([]);
  const [searchActive, setSearchActive] = React.useState(false);

  const handleInputChange = async (e) => {
    const len = e.target.value.length;
    if (e.target.value === '') {
      console.log('clear input', e.target.value);
      setSearchActive(false);
      setSearchcampaign([]);
    }
    if (len > 1) {
      setSearchActive(true);
      console.log('search:', e.target.value);
      const filtered = campaigns.filter(
        (i) =>
          i.team_name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
      );
      console.log({ filtered });
      setSearchcampaign(filtered);
    }
  };

  return (
    <span>
      {loading ? (
        <span>
          <LinearProgress />
          loading...
        </span>
      ) : (
        <span>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                All Campaigns ({campaigns.length})
              </Typography>
              <span></span>
            </CardContent>
            {campaigns !== undefined ? (
              <span>
                <CampaignsTable
                  id="campaigns"
                  rows={campaigns}
                  columns={campaign_columns}
                />
              </span>
            ) : (
              <span>
                <p>No findings defined</p>
              </span>
            )}
          </Card>
        </span>
      )}
    </span>
  );
}
