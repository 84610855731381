import * as React from 'react';
import { SvgIcon } from '@mui/material';

function PagerdutyIcon(props) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 120 135"
      {...props}
    >
      <path
        d="M52 4.64L9.56 29.15A16 16 0 001.57 43v49a16 16 0 008 13.84L52 130.36a16 16 0 0016 0l42.45-24.51a16 16 0 008-13.84V43a16 16 0 00-8-13.84L68 4.64a16 16 0 00-16 0z"
        fill="#00c74f"
      />
      <path
        d="M48.33 51.49H36.74a9.26 9.26 0 00-9.24 9.23v34.47h6.82v-12a9.2 9.2 0 002.43.32h11.59a9.26 9.26 0 009.24-9.23V60.72a9.27 9.27 0 00-9.25-9.23zm2.43 22.79a2.44 2.44 0 01-2.43 2.43h-14v-16a2.44 2.44 0 012.43-2.43h11.58a2.44 2.44 0 012.43 2.43v13.57zM71.67 83.51h11.59a9.26 9.26 0 009.24-9.23V39.81h-6.82v12a9.2 9.2 0 00-2.43-.32H71.67a9.26 9.26 0 00-9.24 9.23v13.56a9.25 9.25 0 009.24 9.23zm-2.43-22.79a2.44 2.44 0 012.43-2.43h14v16a2.44 2.44 0 01-2.43 2.43H71.67a2.44 2.44 0 01-2.43-2.43V60.72z"
        fill="#fff"
      />
    </SvgIcon>
  );
}

export default PagerdutyIcon;
