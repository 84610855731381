// AuthZ Hierarchy
// puffcampaigns-admins: can edit/troubleshoot all services (isCampaignsAdmin)
// owner of service : can edit/troubleshoot his own service (isOwnerOrAdmin)
import React from 'react';
import { Avatar, Button } from '@mui/material';
import { Link } from 'react-router-dom';

export function isOwnerOrAdmin(service) {
  // User is authorized on a service (or team) when they have a "can_edit property" (from campaigns API):
  // A user (or manager) has can_edit == true if the owns the campaign, or if they are a manager of a campaign they own
  // alternatively, members of the OAuth2 group "puff-campaigns-admins" are also automatically authorized.
  return service.can_edit || false;
}

export function formatTeamSlug(teamSlug) {
  if (!teamSlug) {
    return '';
  }
  teamSlug = teamSlug.replace('-', ' ');

  if (teamSlug.length <= 4) {
    return teamSlug.toUpperCase();
  } else {
    return teamSlug.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}

export function formatTeamSlugPrint(teamSlug) {
  if (!teamSlug) {
    return '';
  }
  teamSlug = teamSlug.replace('-', ' ');

  if (teamSlug.length <= 4) {
    return teamSlug.toUpperCase();
  } else {
    return teamSlug.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}

export function renderPerson(person) {
  return (
    <>
      <Button
        size="small"
        startIcon={
          <Avatar
            alt={person?.full_name}
            src={person?.slack_photo}
            sx={{ width: 22, height: 22 }}
          />
        }
        component={Link}
        to={`/person/${person?.email?.replace('@gopuff.com', '')}`}
      >
        {' '}
        {person?.first_name
          ? `${person?.first_name} ${person.last_name}`
          : 'N/A'}
      </Button>
    </>
  );
}

export const resourceLifecycleMap = {
  uat: 'Staging',
  dev: 'Development',
  prod: 'Production',
};

export function isCampaignsAdmin(auth) {
  // Check if user is a member of the OAuth2 group "puff-campaigns-admins"
  // This is useful for feature flags, or for hiding/showing certain UI elements
  const resourceAccess = auth.userData.profile.resource_access;
  if (!resourceAccess || !Array.isArray(resourceAccess)) {
    return false;
  }
  for (const resource of resourceAccess) {
    if (
      resource.hasOwnProperty('puff-campaigns') &&
      Array.isArray(resource['puff-campaigns'].roles) &&
      resource['puff-campaigns'].roles.includes('puff-campaigns-admins')
    ) {
      return true;
    }
  }
}

export const statusColor = {
  Active: '#25b225',
  Experimental: '#ce44ec',
  Deprecated: '#da4103',
};
