import React, { useState } from 'react';
import { Cancel, Edit, PublishOutlined } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  IconButton,
  Link,
  TextField,
} from '@mui/material';
import { handlerApiError } from '../shared/errorHandler';

export function EditableLinkField({
  fieldname,
  fieldvalue,
  item,
  updateAction,
  canEdit,
}) {
  const [editvalue, setEditValue] = useState(fieldvalue);
  const [savedvalue, setSavedValue] = useState(fieldvalue);
  const [editMode, setEditMode] = useState(false);
  const [error, setErrorMsg] = useState('');
  const [saving, setSaving] = useState(false);
  const editComponent =
    canEdit === true ? (
      <IconButton
        color="primary"
        onClick={() => {
          setEditMode(true);
        }}
      >
        <Edit fontSize="small" />
      </IconButton>
    ) : (
      ''
    );

  const handleFieldSave = async () => {
    setSaving(true);
    const data = { [fieldname]: editvalue };
    item[fieldname] = editvalue;
    try {
      await updateAction(item, data);
      setSavedValue(editvalue);
      setEditMode(false); // close edit textfield
      setSaving(false);
    } catch (ex) {
      handlerApiError(ex, setErrorMsg);
      setSaving(false);
    }
  };

  const handleCancel = () => {
    setEditMode(false); // close editable textfield
    setErrorMsg(''); // clear error msg
    setEditValue(savedvalue); // reset to initial value
  };

  return (
    <span>
      {editMode ? (
        <span>
          <TextField
            size="small"
            fullWidth
            placeholder="https://..."
            id={`field_${fieldname}`}
            defaultValue={editvalue}
            onChange={(e) => {
              setEditValue(e.target.value);
            }}
          />
          <Button
            size="small"
            id={`save_${fieldname}`}
            variant="outlined"
            onClick={handleFieldSave}
            startIcon={<PublishOutlined />}
          >
            Save
            {saving && <CircularProgress />}
          </Button>
          <Button
            size="small"
            style={{ marginLeft: 7 }}
            id={`cancel_${fieldname}`}
            variant="outlined"
            onClick={handleCancel}
            color="error"
            startIcon={<Cancel />}
          >
            Cancel
          </Button>

          <div>{error}</div>
        </span>
      ) : (
        <span>
          {editComponent}
          <Link href={editvalue} target="blank">
            {editvalue
              ? editvalue
                  ?.replace('https://', '')
                  .replace('gopuff.pagerduty.com/', '')
                  .replace('service-directory/', '')
              : 'N/A'}
          </Link>
        </span>
      )}
    </span>
  );
}
