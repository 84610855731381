import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import {
  Avatar,
  Tooltip,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  FormControlLabel,
  Switch,
  TableBody,
  TableCell,
  Chip,
  Link,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Link as RLink } from 'react-router-dom';
import { Circle } from '@mui/icons-material';
import { getComparator } from '../shared/data-utils';
import ResourceIcon from './ResourceIcon';
import LanguageIcon from './LanguageIcon';

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, columns: headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              display: {
                xs: headCell.hide_mobile ? 'none' : 'table-cell',
                sm: 'table-cell',
              },
            }}
            key={headCell.id}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label === 'Status' ? '' : headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function SortableTable({ rows, columns, id }) {
  const orderStorage = localStorage.getItem(`order_${id}`) || 'asc';
  const [order, setOrder] = React.useState(orderStorage);
  let defaultColumn = columns[0].id;
  if (columns[0].id === 'status') {
    defaultColumn = columns[1].id;
  }

  const defaultColumnStorage =
    localStorage.getItem(`sort_${id}`) || defaultColumn;
  const [orderBy, setOrderBy] = React.useState(defaultColumnStorage);
  const [dense, setDense] = React.useState(true);
  const statusColor = {
    Active: '#25b225',
    Experimental: '#ce44ec',
    Deprecated: '#da4103',
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    localStorage.setItem(`order_${id}`, isAsc ? 'desc' : 'asc');
    localStorage.setItem(`sort_${id}`, property);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  function CellContent({ row, column }) {
    let isArray = false;
    if (Array.isArray(row[column.id])) {
      isArray = true;
    }

    return (
      <span>
        {isArray && (
          <span>
            {row[column.id].map((val) => (
              <div key={val}>
                {column.route ? (
                  <Link component={RLink} to={`/${column.resource}/${val}`}>
                    {val}
                  </Link>
                ) : (
                  <span>{val}</span>
                )}
              </div>
            ))}
          </span>
        )}
        {!isArray && (
          <span>
            {column.route && (
              <Link
                component={RLink}
                to={`/${column.resource}/${row[column.id]}`}
              >
                {row[column.display] || row[column.id]}
              </Link>
            )}

            {column.id === 'language' && (
              <LanguageIcon
                name={row.language}
                sx={{
                  width: 18,
                  height: 18,
                }}
              />
            )}

            {column.id === 'deploy_target' && (
              <Tooltip title={`Target: ${row.deploy_target || 'Unknown'}`}>
                <Avatar
                  alt={row.deploy_target}
                  src={
                    row.deploy_target
                      ? `/assets/${row.deploy_target
                          .replace('/', '_')
                          .replace(' ', '_')}.png`
                      : '/assets/unknown_deploy.png'
                  }
                  sx={{ width: 18, height: 18 }}
                />
              </Tooltip>
            )}
            {column.label === 'Status' && (
              <Circle
                title={row[column.id]}
                sx={{
                  padding: '0px',
                  width: '10px',
                  height: '10px',
                  color: statusColor[row[column.id]],
                }}
              />
            )}
            {typeof row[column.id] === 'undefined' &&
              !column.hide_text &&
              (row[column.display] || '-')}
            {typeof (row[column.id] !== 'undefined') &&
              !column.route &&
              !column.hide_text &&
              !column.chip &&
              !column.icons &&
              (column.numeric
                ? parseFloat(row[column.id]) || '-'
                : row[column.display] || row[column.id])}
            {column.chip && (
              <Chip
                size="small"
                label={row[column.display] || row[column.id]}
                variant="outlined"
              />
            )}
            {column.icons && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ResourceIcon name={row[column.id]} />
                <Typography
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'table-cell',
                    },
                  }}
                  variant="body2"
                  style={{ marginLeft: '8px' }}
                >
                  {row[column.id]}
                </Typography>
              </div>
            )}
          </span>
        )}
      </span>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 1 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 320 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              columns={columns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.sort(getComparator(order, orderBy)).map((row, index) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row[columns[0].id] + index}
                >
                  {columns.slice(0, 9).map((col) => (
                    <TableCell
                      sx={{
                        display: {
                          xs: col.hide_mobile ? 'none' : 'table-cell',
                          sm: 'table-cell',
                        },
                      }}
                      key={col.id}
                    >
                      <CellContent row={row} column={col} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <FormControlLabel
        sx={{ display: { xs: 'none', sm: 'table-cell' } }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}
