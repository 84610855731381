import React, { useState } from 'react';
import { Cancel, Edit, PublishOutlined } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  IconButton,
  Link,
  TextField,
} from '@mui/material';
import SlackIcon from './SlackIcon';

export function EditableSlackChannel({
  fieldname,
  fieldvalue,
  item,
  updateAction,
  canEdit,
}) {
  const [editvalue, setEditValue] = useState(fieldvalue);
  const [savedvalue, setSavedValue] = useState(fieldvalue);
  const [editMode, setEditMode] = useState(false);
  const [error, setErrorMsg] = useState('');
  const [saving, setSaving] = useState(false);
  const editComponent =
    canEdit === true ? (
      <IconButton
        color="primary"
        onClick={() => {
          setEditMode(true);
        }}
      >
        <Edit fontSize="small" />
      </IconButton>
    ) : (
      ''
    );

  const channelUrl = `https://gopuffsquad.slack.com/channels/${editvalue?.replace(
    '#',
    ''
  )}`;

  const handleFieldSave = async () => {
    setSaving(true);
    const data = { [fieldname]: editvalue };
    item[fieldname] = editvalue;
    try {
      await updateAction(item, data);
      setSavedValue(editvalue);
      setEditMode(false); // close edit textfield
      setSaving(false);
    } catch (ex) {
      const msg =
        ex.response?.data?.error ||
        ex.response?.data?.messages[0] ||
        ex.message;
      setErrorMsg(msg);
      setSaving(false);
    }
  };

  const handleCancel = () => {
    setEditMode(false); // close editable textfield
    setErrorMsg(''); // clear error msg
    setEditValue(savedvalue); // reset to initial value
  };

  return (
    <span>
      {editMode ? (
        <span>
          <TextField
            fullWidth
            placeholder="#slack-channel-name"
            id={`field_${fieldname}`}
            defaultValue={editvalue}
            onChange={(e) => {
              setEditValue(e.target.value);
            }}
          />
          <Button
            size="small"
            id={`save_${fieldname}`}
            variant="outlined"
            onClick={handleFieldSave}
            startIcon={<PublishOutlined />}
          >
            Save
            {saving && <CircularProgress />}
          </Button>
          <Button
            size="small"
            style={{ marginLeft: 7 }}
            id={`cancel_${fieldname}`}
            variant="outlined"
            onClick={handleCancel}
            color="error"
            startIcon={<Cancel />}
          >
            Cancel
          </Button>

          <div>{error}</div>
        </span>
      ) : (
        <span>
          {editComponent}
          <Button
            size="small"
            endIcon={<SlackIcon sx={{ width: 16, height: 16 }} />}
            component={Link}
            href={channelUrl}
            target="blank"
          >
            {editvalue?.replace('https://', '').substring(0, 50) || 'N/A'}
          </Button>
        </span>
      )}
    </span>
  );
}
