import React from 'react';
import axios from 'axios';
import Config from '../shared/Config';
import { handlerApiError } from '../shared/errorHandler';

const { puffdirBaseUrl } = Config();
const endpointUrl = `${puffdirBaseUrl}/api/person`;
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const cache = {};

export default function usePerson(email) {
  const access_token = window.sessionStorage.getItem('token');
  headers.Authorization = `Bearer ${access_token}`;
  const [loading, setLoading] = React.useState(true);
  const [person, setPerson] = React.useState({});

  const getPerson = (config, email) => {
    config.url = `${endpointUrl}/${email.replace('@gopuff.com', '')}`;
    axios(config)
      .then((response) => {
        const { data } = response;
        setPerson(data);
        cache[email] = data;
        setLoading(false);
      })
      .catch((err) => handlerApiError(err));
  };

  React.useEffect(() => {
    const config = { url: endpointUrl, headers };
    if (cache[email]) {
      setPerson(cache[email]);
      setLoading(false);
    } else {
      getPerson(config, email);
    }
  }, [email]);

  return { loading, person };
}
