import React from 'react';
import { Avatar } from '@mui/material';

function LanguageIcon({ name, sx }) {
  name = name?.toLowerCase().replace(' ', '') || 'unknown';
  const defaultSx = {
    width: 18,
    height: 18,
  };
  const icon_url = `/assets/languages/${name}_avatar.svg`;
  return (
    <Avatar
      alt={name}
      title={name}
      src={icon_url}
      sx={{ ...defaultSx, ...sx }}
    />
  );
}

export default LanguageIcon;
