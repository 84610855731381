import React from 'react';
import {
  Button,
  Box,
  Card,
  CardContent,
  LinearProgress,
  TableCell,
  TableRow,
  List,
  ListItemText,
  ListItemIcon,
  Typography,
  Avatar,
  Chip,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Link, useParams } from 'react-router-dom';
import { Settings } from '@mui/icons-material';
import { BasicTable } from '../components/BasicTable';
import useTeam from '../hooks/useTeam';
import { EditableField } from '../components/EditableField';
import { EditableArrayField } from '../components/EditableArrayField';
import { EditableLinkField } from '../components/EditableLinkField';
import { EditableSlackChannel } from '../components/EditableSlackChannel';
import { EditableZoomLink } from '../components/EditableZoomLink';

export default function MyCampaignsPage(params) {
  const { teamname } = useParams();
  const { team, loading, updateTeam } = useTeam(teamname);
  const statusColor = {
    Active: '#25b225',
    Experimental: '#ce44ec',
    Deprecated: '#da4103',
  };

  // TODO: Add owned and my team&apos;s campaigns
  return (
    <span>
      <Card sx={{ p: 1, minHeight: 400 }}>
        <Typography
          sx={{ textAlign: 'left' }}
          variant="h6"
          component="h2"
          gutterBottom
        >
          My Campaigns
        </Typography>
      </Card>
      {loading ? (
        <span>
          <LinearProgress />
          loading...
        </span>
      ) : (
        <span>
          <Card variant="outlined" sx={{ minWidth: 325 }}>
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom>
                <strong>{team.team_name}</strong>
              </Typography>
              <BasicTable>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>
                    <EditableField
                      canEdit={team.can_edit}
                      item={team}
                      isMultiline
                      updateAction={updateTeam}
                      fieldname="team_description"
                      fieldvalue={team.team_description}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Keywords</TableCell>
                  <TableCell>
                    <EditableArrayField
                      canEdit={team.can_edit}
                      item={team}
                      updateAction={updateTeam}
                      fieldname="area_of_focus"
                      fieldvalue={team?.area_of_focus}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Team Slack</TableCell>
                  <TableCell>
                    <EditableSlackChannel
                      canEdit={team?.can_edit}
                      item={team}
                      updateAction={updateTeam}
                      fieldname="team_slack"
                      fieldvalue={team?.team_slack}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Team Zoom</TableCell>
                  <TableCell>
                    <EditableZoomLink
                      canEdit={team?.can_edit}
                      item={team}
                      updateAction={updateTeam}
                      fieldname="zoom_link"
                      fieldvalue={team?.zoom_link}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Wiki</TableCell>
                  <TableCell>
                    <EditableLinkField
                      canEdit={team?.can_edit}
                      item={team}
                      updateAction={updateTeam}
                      fieldname="wiki"
                      fieldvalue={team?.wiki}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Domain</TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      color="success"
                      variant="outlined"
                      label={team.domain}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Sub-Domain</TableCell>
                  <TableCell>
                    {team['sub-domain'] ? (
                      <Chip
                        size="small"
                        color="success"
                        variant="outlined"
                        label={team['sub-domain']}
                      />
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Team Type</TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      color="success"
                      variant="outlined"
                      label={team.team_type}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Manager</TableCell>
                  <TableCell>
                    {Array.isArray(team.engineering_manager) ? (
                      <div>
                        {team.engineering_manager?.map((person) => (
                          <div key={person.email}>
                            <Button
                              startIcon={
                                <Avatar
                                  alt={person.name}
                                  src={person.slack_photo}
                                  sx={{
                                    width: 25,
                                    height: 25,
                                  }}
                                />
                              }
                              component={Link}
                              to={`/person/${person.email.replace(
                                '@gopuff.com',
                                ''
                              )}`}
                            >
                              {person?.name}
                            </Button>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <Button
                        startIcon={
                          <Avatar
                            alt={team.engineering_manager?.full_name}
                            src={team.engineering_manager?.slack_photo}
                            sx={{
                              width: 25,
                              height: 25,
                            }}
                          />
                        }
                        component={Link}
                        to={`/person/${team.engineering_manager?.email.replace(
                          '@gopuff.com',
                          ''
                        )}`}
                      >
                        {team.engineering_manager?.name || 'N/A'}
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
                {team.score !== 10 && (
                  <TableRow>
                    <TableCell>Score</TableCell>
                    <TableCell>{team.score}</TableCell>
                  </TableRow>
                )}

                {team.score_details?.errors?.length ||
                team.score_details?.warnings?.length ? (
                  <TableRow>
                    <TableCell>Errors & Warnings</TableCell>
                    <TableCell>
                      <List sx={{ padding: 0 }}>
                        {team.score_details?.errors?.map((e, i) => (
                          <ListItemText
                            primaryTypographyProps={{
                              fontSize: 14,
                            }}
                            key={i}
                          >
                            <ListItemIcon
                              style={{
                                top: '2px',
                                position: 'relative',
                                minWidth: '30px',
                              }}
                            >
                              <ErrorOutlineIcon
                                sx={{
                                  color: 'red',
                                  width: 13,
                                  height: 13,
                                }}
                              />
                            </ListItemIcon>
                            {e}
                          </ListItemText>
                        ))}
                        {team.score_details.warnings.map((e, i) => (
                          <ListItemText
                            primaryTypographyProps={{
                              fontSize: 14,
                            }}
                            key={i}
                          >
                            <ListItemIcon
                              style={{
                                top: '2px',
                                position: 'relative',
                                minWidth: '30px',
                              }}
                            >
                              <WarningAmberIcon
                                sx={{
                                  color: 'orange',
                                  width: 13,
                                  height: 13,
                                }}
                              />
                            </ListItemIcon>
                            {e.replace('area_of_focus', 'keywords')}
                          </ListItemText>
                        ))}
                      </List>
                    </TableCell>
                  </TableRow>
                ) : null}
              </BasicTable>
              <BasicTable>
                <TableRow>
                  <TableCell>
                    <b>People</b> ({team.eng_team_members?.length})
                  </TableCell>
                </TableRow>
                {team.eng_team_members?.map((p) => (
                  <TableRow key={p.email}>
                    <TableCell
                      sx={{
                        padding: '2px',
                        paddingLeft: '15px',
                      }}
                      colSpan={2}
                    >
                      <Button
                        startIcon={
                          <Avatar
                            alt={p.full_name}
                            src={p.slack_photo}
                            sx={{
                              width: 25,
                              height: 25,
                            }}
                          />
                        }
                        component={Link}
                        to={`/person/${p.email.replace('@gopuff.com', '')}`}
                      >
                        {p?.name}
                      </Button>
                      {p.title}
                    </TableCell>
                  </TableRow>
                ))}
              </BasicTable>
              <BasicTable>
                <TableRow>
                  <TableCell>
                    <b>Services</b> ({team.services ? team.services.length : 0})
                    and current <b>oncall</b>
                  </TableCell>
                </TableRow>
                {team.services
                  ?.sort((a, b) => {
                    const statusOrder = [
                      'Active',
                      'Experimental',
                      'Deprecated',
                    ];
                    return (
                      statusOrder.indexOf(a.status) -
                      statusOrder.indexOf(b.status)
                    );
                  })
                  .map((s, idx) => (
                    <TableRow key={idx}>
                      <TableCell
                        sx={{
                          padding: '0px',
                          paddingLeft: '15px',
                        }}
                        colSpan={2}
                      >
                        <Box display="flex" justifyContent="space-between">
                          <Box display="flex" justifyContent="flex-start">
                            <Button
                              startIcon={
                                <Settings
                                  sx={{
                                    color: statusColor[s.status],
                                  }}
                                />
                              }
                              component={Link}
                              to={`/service/${s.service_name}`}
                            >
                              {s.service_name} (<strong>{s.tier || '-'}</strong>
                              )
                            </Button>
                          </Box>
                          {s.status === 'Active' && (
                            <Box display="flex" justifyContent="flex-end">
                              <Button
                                size="small"
                                startIcon={
                                  <Avatar
                                    alt={s.oncall?.full_name}
                                    src={s.oncall?.slack_photo}
                                    sx={{
                                      width: 25,
                                      height: 25,
                                    }}
                                  />
                                }
                                component={Link}
                                to={`/person/${s.oncall?.email?.replace(
                                  '@gopuff.com',
                                  ''
                                )}`}
                              >
                                {s.oncall?.name ? s.oncall?.name : 'N/A'}
                              </Button>
                            </Box>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </BasicTable>
            </CardContent>
          </Card>
        </span>
      )}
    </span>
  );
}
