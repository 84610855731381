import configJson from '../config.json';

const { REACT_APP_CONFIG, REACT_APP_REDIRECT_URI } = process.env; // convention used by Create React App

export default function Config() {
  let config = configJson.default; // default env
  if (REACT_APP_CONFIG) {
    config = configJson[REACT_APP_CONFIG];
    if (REACT_APP_CONFIG === 'pr') {
      config.openid.redirectUri = REACT_APP_REDIRECT_URI;
    }
  }
  return config;
}
