import React from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';

export function BasicTable({ children }) {
  return (
    <TableContainer>
      <Table size="small" sx={{ minWidth: 340 }}>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
}
