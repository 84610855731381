import aox from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import MuiGrid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Card, LinearProgress, TextField, Typography } from '@mui/material';
import React from 'react';

import { Link, useParams } from 'react-router-dom';
import CampaignsTable from '../components/CampaignsTable';
import useCategories from '../hooks/useCategories';
export default function CategoriesPage(params) {
  const { categories, loading } = useCategories();
  const [searchcategory, setSearchcategory] = React.useState([]);
  const [searchActive, setSearchActive] = React.useState(false);

  const category_columns = [
    // {
    // id: 'id',
    // display: 'ID',
    // // TODO: add id-based routing in backend
    // //route: true,
    // //resource: 'categories',
    // numeric: false,
    // disablePadding: false,
    // label: 'ID',
    // },
    {
      id: 'category',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
  ];
  const handleInputChange = async (e) => {
    const len = e.target.value.length;
    if (e.target.value === '') {
      console.log('clear input', e.target.value);
      setSearchActive(false);
      setSearchcategory([]);
    }
    if (len > 1) {
      setSearchActive(true);
      console.log('search:', e.target.value);
      const filtered = categories.filter(
        (i) =>
          i.team_name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
      );
      console.log({ filtered });
      setSearchcategory(filtered);
    }
  };

  return (
    <span>
      {
        // TODO: add list of campaigns in each category (and show counts at the top of each table)
      }
      {loading ? (
        <span>
          <LinearProgress />
          loading...
        </span>
      ) : (
        <span>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                All Categories ({categories.length})
              </Typography>
              <span></span>
            </CardContent>
            {categories !== undefined ? (
              <span>
                <CampaignsTable
                  id="categories"
                  rows={categories}
                  columns={category_columns}
                />
              </span>
            ) : (
              <span>
                <p>No findings defined</p>
              </span>
            )}
          </Card>
        </span>
      )}
    </span>
  );
}
