import CardContent from '@mui/material/CardContent';
import { Card, LinearProgress, TextField, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import CampaignsTable from '../components/CampaignsTable';
import useTeamCampaigns from '../hooks/useTeamCampaigns';
import { campaign_columns, findings_columns } from '../shared/campaigns';
import { formatTeamSlug, isCampaignsAdmin } from '../shared/serviceHandler';
export default function TeamCampaignsPage() {
  const { teamname } = useParams();
  const { campaigns, loading } = useTeamCampaigns(teamname);
  campaign_columns.forEach((column, index) => {
    if (column.id === 'owning_team') {
      campaign_columns.splice(index, 1);
    }
  });

  return (
    <span>
      {loading ? (
        <span>
          <LinearProgress />
          loading...
        </span>
      ) : (
        <span>
          {campaigns !== undefined ? (
            <span>
              <Card>
                {campaigns.owned_campaigns.length === 0 &&
                  campaigns.owned_campaigns.length === 0 && (
                    <Typography
                      variant="h5"
                      component="div"
                      style={{ marginLeft: '10px', paddingTop: '10px' }}
                    >
                      No owned campaigns or findings for{' '}
                      <b>{formatTeamSlug(teamname)}</b>
                    </Typography>
                  )}
                {campaigns.owned_campaigns.length > 0 && (
                  <span>
                    <Typography
                      variant="h5"
                      component="div"
                      style={{ marginLeft: '10px', paddingTop: '10px' }}
                    >
                      <b>{formatTeamSlug(teamname)}</b> Campaigns (
                      {campaigns.owned_campaigns.length})
                    </Typography>
                    <CampaignsTable
                      id="campaigns"
                      rows={campaigns.owned_campaigns}
                      columns={campaign_columns}
                    />
                  </span>
                )}
                {campaigns.actionable_campaigns.length > 0 && (
                  <span>
                    <Typography
                      variant="h5"
                      component="div"
                      style={{ marginLeft: '10px', paddingTop: '10px' }}
                    >
                      <b>{formatTeamSlug(teamname)}</b> Findings (
                      {campaigns.actionable_campaigns.length})
                    </Typography>
                    <CampaignsTable
                      id="campaigns"
                      rows={campaigns.actionable_campaigns}
                      columns={findings_columns}
                    />
                  </span>
                )}
              </Card>
            </span>
          ) : (
            <span>
              <p>No campaigns found</p>
            </span>
          )}
        </span>
      )}
    </span>
  );
}
