import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Button,
  TableBody,
  TableCell,
  Chip,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { visuallyHidden } from '@mui/utils';
import { Link as RLink } from 'react-router-dom';
import { getComparator, toCampaignDate, timeAgo } from '../shared/data-utils';
import ResourceIcon from './ResourceIcon';
import {
  getSeverityColor,
  getSeverityFontColor,
  getStatusColor,
} from '../shared/campaigns';
import { useTheme } from '@mui/material/styles';
import { replaceDescriptionWithStars } from '../shared/campaigns';

function getRandomLetter() {
  var randomCharCode = Math.floor(Math.random() * 26) + 97; // Generate a random number between 97 and 122 (inclusive)
  var randomLetter = String.fromCharCode(randomCharCode); // Convert the random character code to a letter
  return randomLetter;
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, columns: headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow hover>
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              display: {
                xs: headCell.hide_mobile ? 'none' : 'table-cell',
                sm: 'table-cell',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              },
            }}
            key={getRandomLetter() + headCell.id}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label === 'Status' ? '' : headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function CampaignsTable({ rows, columns, id }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const theme = useTheme();
  const orderStorage = localStorage.getItem(`order_${id}`) || 'asc';
  const [order, setOrder] = React.useState(orderStorage);
  let defaultColumn = columns[0].id;

  const defaultColumnStorage =
    localStorage.getItem(`sort_${id}`) || defaultColumn;
  const [orderBy, setOrderBy] = React.useState(defaultColumnStorage);
  const [dense, setDense] = React.useState(true);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    localStorage.setItem(`order_${id}`, isAsc ? 'desc' : 'asc');
    localStorage.setItem(`sort_${id}`, property);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  function CellContent({ row, column }) {
    let isArray = false;
    if (Array.isArray(row[column.id])) {
      isArray = true;
    }

    return (
      <span>
        {isArray && (
          <span>
            {row[column.id].map((val) => (
              <div key={getRandomLetter() + val}>
                {column.route && !column.icon ? (
                  <Link component={RLink} to={`/${column.resource}/${val}`}>
                    {val}
                  </Link>
                ) : (
                  <span>{val}</span>
                )}
              </div>
            ))}
          </span>
        )}
        {!isArray && (
          <span
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              display: 'block',
            }}
          >
            {column.route && !column.icon && (
              <Link
                component={RLink}
                to={
                  column.slug
                    ? `/${column.resource}/${row['slug']}`
                    : column.by_id
                    ? `/${column.resource}/${row['id']}`
                    : `/${column.resource}/${row[column.id]}`
                }
              >
                {column.replaceWithStars
                  ? replaceDescriptionWithStars(row[column.display]) ||
                    replaceDescriptionWithStars(row[column.id])
                  : row[column.display] || row[column.id]}
              </Link>
            )}
            {column.date && !column.ago && !column.chip && (
              <span>{toCampaignDate(row[column.id])}</span>
            )}
            {column.date && column.ago && !column.chip && (
              <Tooltip title={`${row[column.id].slice(0, -13)} UTC`}>
                <span>{timeAgo(row[column.id])} ago</span>
              </Tooltip>
            )}
            {typeof row[column.id] === 'undefined' &&
              !column.hide_text &&
              (row[column.display] || '-')}
            {typeof (row[column.id] !== 'undefined') &&
              !column.route &&
              !column.hide_text &&
              !column.chip &&
              !column.external &&
              !column.date &&
              !column.icons &&
              (column.numeric
                ? parseFloat(row[column.id]) || '-'
                : row[column.display] || row[column.id])}
            {column.chip && (
              <Chip
                size="small"
                component="a"
                style={{
                  fontWeight: 'bold',
                  backgroundColor: getSeverityColor(row['severity'] || null),
                  color: getSeverityFontColor(row['severity'] || null),
                }}
                label={
                  column.date
                    ? toCampaignDate(row[column.id])
                    : row[column.display] || row[column.id]
                }
                variant="filled"
                color="primary"
              />
            )}
            {column.icon && (
              <Button
                size="small"
                target={column.external ? '_blank' : null}
                startIcon={column.icon}
                href={`${column.resource || '/'}/${row[column.id].replace(
                  '@gopuff.com',
                  ''
                )}`}
              >
                {row[column.id].replace('@gopuff.com', '')}
              </Button>
            )}
            {column.external && !column.icon ? (
              <a
                href={
                  column.resource
                    ? `${column.resource}/${row[column.id]}`
                    : row[column.id]
                }
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: column.disableTruncate ? 1 : '20ch',
                  display: 'block',
                }}
              >
                {row[column.id]
                  .replace('@gopuff.com', '')
                  .replace(
                    /https:\/\/github\.com\/gopuff\/(?<repo>[-a-zA-Z0-9]+)\/blob\/(?<branch>[a-zA-Z0-9]+)\/(?<dir>[a-zA-Z0-9]+)?\/(?<filename>[a-zA-Z0-9\.]+)/,
                    (match, repo, branch, dir, filename) => filename
                  )}
              </a>
            ) : (
              column.icons &&
              !column.chip &&
              !column.external &&
              !column.date && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ResourceIcon name={row[column.id]} />
                  <Typography
                    sx={{
                      display: {
                        xs: 'none',
                        sm: 'table-cell',
                      },
                    }}
                    variant="body2"
                    style={{
                      marginLeft: '8px',
                    }}
                  >
                    {row[column.id]}
                  </Typography>
                </div>
              )
            )}
          </span>
        )}
      </span>
    );
  }

  return (
    <Box sx={{ width: 'xl' }}>
      <Paper sx={{ width: 'xl', mb: 1 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 'lg', maxWidth: 'xl' }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              columns={columns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={getRandomLetter() + row[columns[0].id] + index}
                    style={{ backgroundColor: getStatusColor(row.status) }}
                  >
                    {columns.map((col) => (
                      <TableCell
                        sx={{
                          display: {
                            xs: col.hide_mobile ? 'none' : 'table-cell',
                            sm: 'table-cell',
                          },
                        }}
                        key={getRandomLetter() + col.id}
                      >
                        <CellContent
                          sx={{
                            //display: 'table-cell',

                            style: {
                              width: 'auto',
                            },
                          }}
                          row={row}
                          column={col}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/*
      <FormControlLabel
        sx={{ display: { xs: 'none', sm: 'table-cell' } }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      */}
    </Box>
  );
}
