import React from 'react';
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
  useMediaQuery,
} from '@mui/material';
import { red } from '@mui/material/colors';

export function ThemeProvider({ children }) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const runEnv = process.env.REACT_APP_CONFIG;

  const defaultThemeConfig = {
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
      primary: {
        main: runEnv === 'prod' ? '#1B5D9B' : '#1DBB55',
      },
      secondary: {
        main: '#ffffff',
      },
      error: {
        main: red.A400,
      },
      neutral: {
        main: '#64748B',
        contrastText: '#fff',
      },
    },
  };
  const theme = createTheme(defaultThemeConfig);

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
