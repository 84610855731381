import React from 'react';
import axios from 'axios';
import Config from '../shared/Config';
import { handlerApiError } from '../shared/errorHandler';

const { apiBaseUrl } = Config();
const endpointUrl = `${apiBaseUrl}`;
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const cache = {};

export default function useFindings(campaign, status = 'open') {
  console.log(`useFindings called for campaign: ${campaign} status: ${status}`);
  const access_token = window.sessionStorage.getItem('token');
  headers.Authorization = `Bearer ${access_token}`;
  const [findings_loading, setLoading] = React.useState(true);
  const [findings, setFindings] = React.useState({});

  const getFindings = (config, campaign, status) => {
    console.log(config);
    config.url = `${endpointUrl}/campaigns/${campaign}/findings?status=${status}`;
    axios(config)
      .then((response) => {
        const { data } = response;
        setFindings(data);
        cache[campaign] = data;
        setLoading(false);
      })
      .catch((err) => handlerApiError(err));
  };

  React.useEffect(() => {
    const config = { url: endpointUrl, headers };
    if (cache[campaign]) {
      setFindings(cache[campaign]);
      setLoading(false);
    } else {
      getFindings(config, campaign, status);
    }
  }, [campaign, status]);

  return { findings_loading, findings };
}
