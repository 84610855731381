import React from 'react';
import axios from 'axios';
import Config from '../shared/Config';
import { handlerApiError } from '../shared/errorHandler';

const { apiBaseUrl } = Config();
const endpointUrl = `${apiBaseUrl}/campaigns`;
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const cache = {};

export default function useCampaign(campaignName) {
  const access_token = window.sessionStorage.getItem('token');
  headers.Authorization = `Bearer ${access_token}`;
  const [loading, setLoading] = React.useState(true);
  const [campaign, setCampaign] = React.useState({});
  const [p_loading, setPLoading] = React.useState(true);
  const [person, setPerson] = React.useState({});

  const getCampaign = (config, campaignName) => {
    config.url = `${endpointUrl}/${campaignName}`;
    axios(config)
      .then((response) => {
        const { data } = response;
        setCampaign(data);
        cache[campaignName] = data;
        setLoading(false);
      })
      .catch((err) => handlerApiError(err));
  };

  const getPerson = (config, email) => {
    axios(config)
      .then((response) => {
        const { data } = response;
        setPerson(data);
        cache[email] = data;
        setPLoading(false);
      })
      .catch((err) => handlerApiError(err));
  };

  React.useEffect(() => {
    const config = { url: endpointUrl, headers };
    if (cache[campaignName]) {
      setCampaign(cache[campaignName]);
      setLoading(false);
    } else {
      getCampaign(config, campaignName);
    }
  }, [campaignName]);

  React.useEffect(() => {
    if (campaign.campaign === undefined) return;
    const email = campaign.campaign.created_by;
    const config = {
      url: `https://puffdirectory.sre.gopuff.com/api/person/${email}`,
      headers,
    };
    if (cache[email]) {
      setPerson(cache[email]);
      setPLoading(false);
    } else {
      getPerson(config, email);
    }
  }, [campaign]);

  return { loading, campaign, p_loading, person };
}
