import React from 'react';
import axios from 'axios';
import Config from '../shared/Config';
import { handlerApiError } from '../shared/errorHandler';

const { apiBaseUrl } = Config();
const endpointUrl = `${apiBaseUrl}/campaigns`;
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export default function useCampaigns() {
  const access_token = window.sessionStorage.getItem('token');
  headers.Authorization = `Bearer ${access_token}`;
  const [loading, setLoading] = React.useState(true);
  const [campaigns, setCampaign] = React.useState({});

  const getCampaigns = (config) => {
    config.url = endpointUrl;
    axios(config)
      .then((response) => {
        const { data } = response;
        setCampaign(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(`Error rendering page: ${err.message}`);
        handlerApiError(err);
      });
  };

  React.useEffect(() => {
    const config = { url: endpointUrl, headers };
    getCampaigns(config);
  }, []);

  // const updateCampaign = (campaign, json) => {
  //   const config = {
  //     url: `${endpointUrl}/${campaign.campaign_slug}`,
  //     headers,
  //     method: 'put',
  //     data: json,
  //   };
  //   return axios(config);
  // };

  return { loading, campaigns };
}
