import React, { useState } from 'react';
import { Cancel, Edit, PublishOutlined } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Chip,
} from '@mui/material';
import { handlerApiError } from '../shared/errorHandler';

export function EditableArrayField({
  fieldname,
  fieldvalue,
  item,
  updateAction,
  canEdit,
}) {
  const _fieldValue = Array.isArray(fieldvalue) ? fieldvalue.join(',') : '';
  const [editvalue, setEditValue] = useState(_fieldValue);
  const [savedvalue, setSavedValue] = useState(_fieldValue);
  const [editMode, setEditMode] = useState(false);
  const [error, setErrorMsg] = useState('');
  const [saving, setSaving] = useState(false);
  const editComponent =
    canEdit === true ? (
      <IconButton
        color="primary"
        onClick={() => {
          setEditMode(true);
        }}
      >
        <Edit fontSize="small" />
      </IconButton>
    ) : (
      ''
    );

  const handleFieldSave = async () => {
    setSaving(true);
    const data = { [fieldname]: editvalue };
    item[fieldname] = editvalue.split(',');
    try {
      await updateAction(item, data);
      setSavedValue(editvalue);
      setEditMode(false); // close edit textfield
      setSaving(false);
    } catch (ex) {
      handlerApiError(ex, setErrorMsg);
      setSaving(false);
    }
  };

  const handleCancel = () => {
    setEditMode(false); // close editable textfield
    setErrorMsg(''); // clear error msg
    setEditValue(savedvalue); // reset to initial value
  };

  return (
    <span>
      {editMode ? (
        <span>
          <TextField
            size="small"
            fullWidth
            placeholder="keyword1, keyword2, ..."
            id={`field_${fieldname}`}
            defaultValue={editvalue}
            onChange={(e) => {
              setEditValue(e.target.value);
            }}
          />
          {fieldname === 'area_of_focus' && (
            <p>(insert comma separated values)</p>
          )}
          <Button
            size="small"
            id={`save_${fieldname}`}
            variant="outlined"
            onClick={handleFieldSave}
            startIcon={<PublishOutlined />}
          >
            Save
            {saving && <CircularProgress />}
          </Button>
          <Button
            size="small"
            style={{ marginLeft: 7 }}
            id={`cancel_${fieldname}`}
            variant="outlined"
            onClick={handleCancel}
            color="error"
            startIcon={<Cancel />}
          >
            Cancel
          </Button>

          <div>{error}</div>
        </span>
      ) : (
        <span>
          {editComponent}
          {editvalue &&
            editvalue
              .split(',')
              .map((val) => (
                <Chip
                  key={val}
                  size="small"
                  variant="outlined"
                  color="primary"
                  label={val}
                />
              ))}
          {!editvalue && 'N/A'}
        </span>
      )}
    </span>
  );
}
