import React from 'react';
import { Link, Typography } from '@mui/material';

export default function Copyright() {
  return (
    <Typography
      sx={{ mt: 3 }}
      variant="body2"
      color="text.secondary"
      align="center"
    >
      {'Copyright © '}
      <Link color="inherit" href="https://puffdir.sre.gopuff.com/team/SRE">
        SRE @ Gopuff
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}
