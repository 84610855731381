import React from 'react';
import axios from 'axios';
import Config from '../shared/Config';
import { handlerApiError } from '../shared/errorHandler';

const { puffdirBaseUrl } = Config();
const endpointUrl = `${puffdirBaseUrl}/api/team`;
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

let cacheTeams = null;

export default function useTeams() {
  const access_token = window.sessionStorage.getItem('token');
  headers.Authorization = `Bearer ${access_token}`;
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const getTeams = (config) => {
    config.url = `${endpointUrl}s?verbose=true`;
    axios(config)
      .then((response) => {
        const { data } = response;
        console.log('getTeams: fetch', { data });
        setItems(data);
        cacheTeams = data;
        setLoading(false);
      })
      .catch((err) => handlerApiError(err));
  };

  React.useEffect(() => {
    const config = { url: endpointUrl, headers };
    if (cacheTeams) {
      setItems(cacheTeams);
      setLoading(false);
    } else {
      getTeams(config);
    }
  }, []);

  return { loading, items };
}
