import React from 'react';
import { People, Groups, Category } from '@mui/icons-material';

export function replaceDescription(description, additional_fields) {
  // Replace description fields eg: "{team}" with the value from additional_fields.value
  for (const key in additional_fields) {
    if (additional_fields.hasOwnProperty(key)) {
      const variable = `{${key}}`;
      const value = additional_fields[key];
      const regex = new RegExp(variable, 'g');
      description = description.replace(regex, value);
    }
  }
  return description;
}

export function getStatusColor(status) {
  if (!status) return;
  if (status === 'acknowledged') {
    return '#d8f6fe';
  }
  if (status === 'autoresolved') {
    return '#ececec';
  }
  if (status === 'fixed') {
    return '#eeffee';
  }

  return null;
}

export function replaceDescriptionWithStars(description) {
  // Replace every occurrence of "{[a-z]+}"" in description with "*", example "hello {world}" will be replaced with "hello *"
  if (!description) return;
  const regex = new RegExp('{[a-z_]+}', 'g');
  description = description.replace(regex, '*');
  return description;
}

export function getSeverityColor(severity) {
  if (severity === 1) {
    return '#b51c1f';
  }
  if (severity === 2) {
    return '#ff1744';
  }
  if (severity === 3) {
    return '#ed6c02';
  }
  if (severity === 4) {
    return '#0288d1';
  }
  if (severity === 5) {
    return '#adbcc4';
  }
  return;
}

export function getSeverityFontColor(severity) {
  if (severity === 1) {
    return '#fff';
  }
  if (severity === 2) {
    return '#fff';
  }
  if (severity === 3) {
    return '#fff';
  }
  if (severity === 4) {
    return '#fff';
  }
  if (severity === 5) {
    return '#fff';
  }
  return;
}

export const campaign_columns = [
  // {
  // id: 'id',
  // display: 'ID',
  // // TODO: add id-based routing in backend
  // //route: true,
  // //resource: 'campaigns',
  // numeric: false,
  // disablePadding: false,
  // label: 'ID',
  // },
  {
    id: 'due_date',
    label: 'Due',
    chip: true,
    date: true,
  },
  {
    id: 'name',
    numeric: false,
    route: true,
    disablePadding: false,
    label: 'Name',
    resource: 'campaigns',
    slug: true,
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    hide_mobile: true,
  },
  {
    id: 'owning_team',
    numeric: false,
    resource: '/teams',
    hide_mobile: false,
    route: true,
    disablePadding: false,
    label: 'Owning Team',
    icon: <Groups />,
    hide_mobile: true,
  },

  {
    id: 'created_by',
    external: true,
    numeric: false,
    disablePadding: false,
    label: 'Created By',
    resource: 'https://puffdir.sre.gopuff.com/person',
    icon: <People />,
    hide_mobile: true,
  },
  {
    id: 'checked_at',
    label: 'Last Checked',
    numeric: false,
    disablePadding: false,
    date: true,
    ago: true,
    hide_mobile: true,
  },
];

export const findings_columns = [
  {
    id: 'severity',
    numeric: true,
    disablePadding: false,
    label: 'Sev',
    chip: true,
  },
  {
    id: 'due_date',
    numeric: false,
    disablePadding: false,
    label: 'Due Date',
    hide_mobile: true,
    date: true,
  },
  {
    id: 'team',
    numeric: false,
    resource: '/teams',
    hide_mobile: false,
    route: true,
    disablePadding: false,
    label: 'Owner',
    icon: <Groups />,
    hide_mobile: true,
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    disableTruncate: true,
    resource: 'findings',
    by_id: true,
    route: true,
    replaceWithStars: true,
  },
  {
    id: 'last_updated_at',
    label: 'Last Checked',
    numeric: false,
    disablePadding: false,
    date: true,
    ago: true,
    hide_mobile: true,
  },
];
