import React from 'react';
import {
  Assignment,
  Category,
  Campaign,
  AssignmentInd,
  Groups,
  MiscellaneousServices,
  Storage,
} from '@mui/icons-material';
import {
  Card,
  Link,
  Box,
  List,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import { useAuth } from 'oidc-react';
import { Link as RLink } from 'react-router-dom';
import usePerson from '../hooks/usePerson';
import { formatTeamSlug, isCampaignsAdmin } from '../shared/serviceHandler';

// import { useSearch, searchNameFn, searchPathFn } from "../hooks/useSearch";

export default function HomePage() {
  const auth = useAuth();
  const { email } = auth.userData.profile;
  const { person, loading } = usePerson(email);

  // After 1st login, Redirect to entry page.
  if (sessionStorage.getItem('entry.path')) {
    const path = sessionStorage.getItem('entry.path');
    window.location.replace(path); // TODO; figure out why navigate(path) doesn't work here
    sessionStorage.removeItem('entry.path');
  }

  return (
    <span>
      {auth && auth.userData ? (
        <div>
          <Card sx={{ p: 1, minHeight: 400 }}>
            <h2>Welcome to Puff Campaigns!</h2>
            <p>
              This is a tool to manage audits and automations; track, quantify,
              and centralize the various campaigns that we want to monitor over
              time. Examples include certificate expirations, cost reductions,
              and migrations to a particular technology. It also works on
              mobile!
            </p>
            <p>
              For more information, please see{' '}
              <Link
                href="https://gopuff.atlassian.net/wiki/spaces/GD/pages/3237347329/Puff+Campaigns+Guide"
                target="_blank"
                rel="noreferrer"
              >
                the About page
              </Link>
              .
            </p>
            <Typography
              sx={{ textAlign: 'center' }}
              variant="h6"
              component="h2"
              gutterBottom
            >
              My Campaigns
            </Typography>
            <MenuList
              sx={{
                maxWidth: 240,
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              {loading ? (
                <MenuItem key="loading" divider component={RLink}>
                  <ListItemIcon>
                    <Groups fontSize="large" color="primary" />
                  </ListItemIcon>
                  <ListItemText>Loading...</ListItemText>
                </MenuItem>
              ) : (
                person.engineering_teams?.map((team) => (
                  <MenuItem
                    key={formatTeamSlug(team)}
                    divider
                    component={RLink}
                    to={`/teams/${team.toLowerCase()}`}
                  >
                    <ListItemIcon>
                      <Groups fontSize="large" color="primary" />
                    </ListItemIcon>
                    <ListItemText>
                      {formatTeamSlug(team)}&apos;s Findings
                    </ListItemText>
                  </MenuItem>
                ))
              )}

              <MenuItem divider component={RLink} to="/campaigns">
                <ListItemIcon>
                  <Campaign fontSize="large" color="primary" />
                </ListItemIcon>
                <ListItemText> All Campaigns</ListItemText>
              </MenuItem>
              {
                // TODO: get my-campaigns page working
                // <MenuItem divider component={RLink} to="/my-campaigns">
                //   <ListItemIcon>
                //     <AssignmentInd fontSize="large" color="primary" />
                //   </ListItemIcon>
                //   <ListItemText> My Campaigns</ListItemText>
                // </MenuItem>
              }
            </MenuList>
          </Card>
        </div>
      ) : (
        <h2>Login to Okta</h2>
      )}
    </span>
  );
}
