import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Container } from '@mui/material';
import { AuthProvider } from 'oidc-react';
import './App.css';
import { datadogRum } from '@datadog/browser-rum';
import TeamsPage from './pages/TeamsPage';
import TeamPage from './pages/TeamPage';
import TeamCampaignsPage from './pages/TeamCampaignsPage';
import CampaignPage from './pages/CampaignPage';
import CampaignsPage from './pages/CampaignsPage';
import MyCampaignsPage from './pages/MyCampaignsPage';
import CategoriesPage from './pages/CategoriesPage';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import Config from './shared/Config';
import SessionProvider from './Session';
import { RedirectPage } from './pages/RedirectPage';
import Copyright from './components/Copyright';
import PeoplePage from './pages/PeoplePage';
import PersonPage from './pages/PersonPage';
import FindingPage from './pages/FindingPage';

import AppNavigation from './components/AppNavigation';

const { apiBaseUrl } = Config();

const runEnv = process.env.REACT_APP_CONFIG;

datadogRum.init({
  applicationId: '332ef603-c58f-404b-8b50-7ab6684f92a6',
  clientToken: 'pub51c724e3491cedef48102a6f05f100a2',
  site: 'datadoghq.com',
  service: 'puff-campaigns',
  env: runEnv,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 10,
  sessionReplaySampleRate: 10,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [
    {
      match: (url) => url.startsWith(apiBaseUrl),
      propagatorTypes: ['tracecontext', 'b3'],
    },
  ],
});

datadogRum.startSessionReplayRecording();

export default function App() {
  const { openid } = Config();
  // For local env testing
  if (window.location.origin === 'http://localhost:3000') {
    openid.redirectUri = 'http://localhost:3000';
  }
  const oidcConfig = {
    onBeforeSignIn: () => {
      console.log('oidc onBeforeSignIn');
      const { pathname, search } = window.location;
      sessionStorage.setItem('entry.path', pathname + search);
    },
    onSignIn: () => {
      console.log('oidc onSignIn');
      // window.location.replace(window.sessionStorage.getItem("entry.pathname_search"))
    },
    authority: openid.authority,
    clientId: openid.clientId,
    redirectUri: window.location.origin, // openid.redirectUri
  };

  return (
    <div className="App">
      <AuthProvider {...oidcConfig}>
        <SessionProvider>
          <Router>
            <AppNavigation />
            <Container
              id="app-container"
              maxWidth="xl"
              sx={{ mb: 2 }}
              disableGutters
            >
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/redirect" element={<RedirectPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/campaigns" element={<CampaignsPage />} />
                <Route path="/findings/:id" element={<FindingPage />} />
                <Route
                  path="/campaigns/:campaignname"
                  element={<CampaignPage />}
                />
                <Route path="/my-campaigns" element={<MyCampaignsPage />} />
                <Route path="/categories" element={<CategoriesPage />} />
                <Route path="/teams" element={<TeamsPage />} />
                <Route path="/team/:teamname" element={<TeamPage />} />
                <Route
                  path="/categories/:category"
                  element={<CategoriesPage />}
                />
                <Route
                  path="/teams/:teamname"
                  element={<TeamCampaignsPage />}
                />
                <Route path="/person/:email" element={<PersonPage />} />
              </Routes>
              <Copyright />
            </Container>
          </Router>
        </SessionProvider>
      </AuthProvider>
    </div>
  );
}
