import { formatDistanceToNow } from 'date-fns';

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => -descendingComparator(a, b, orderBy)
    : (a, b) => descendingComparator(a, b, orderBy);
}

// Alpha-Numeric sort
export function descendingComparator(a, b, orderBy) {
  const prev = a[orderBy] ? a[orderBy].toString() : '';
  const curr = b[orderBy] ? b[orderBy].toString() : '';

  return prev.localeCompare(curr, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
}

export function daysUntilDate(targetDate) {
  // Convert the target date to a JavaScript Date object
  const target = new Date(targetDate);
  // Get the current date
  const currentDate = new Date();
  // Calculate the time difference in milliseconds
  const timeDiff = target.getTime() - currentDate.getTime();
  // Convert the time difference from milliseconds to days
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return daysDiff;
}

export function toCampaignDate(dateString) {
  if (dateString == 'ongoing' || dateString === undefined) {
    return 'ongoing';
  }
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return dateString;
  }
  const formattedDate = `${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${date
    .getDate()
    .toString()
    .padStart(2, '0')}/${date.getFullYear()}`;
  return formattedDate;
}

export function parseDateWithoutYear(dateString) {
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const today = new Date();
  const targetDay = daysOfWeek.indexOf(dateString.substr(0, 3));
  const targetTime = dateString.substr(4);

  const nextDate = new Date();
  nextDate.setUTCHours(parseInt(targetTime.substr(0, 2)));
  nextDate.setUTCMinutes(parseInt(targetTime.substr(3)));
  nextDate.setUTCSeconds(0);
  nextDate.setUTCMilliseconds(0);

  while (nextDate.getUTCDay() !== targetDay) {
    nextDate.setUTCDate(nextDate.getUTCDate() + 1);
  }

  if (nextDate < today) {
    nextDate.setUTCDate(nextDate.getUTCDate() + 7);
  }

  return nextDate;
}

export function getRemainingTime(targetDate) {
  const targetUTCDate = new Date(parseDateWithoutYear(targetDate));
  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = targetUTCDate - currentDate;

  // Calculate the remaining days, hours, and minutes
  const remainingDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const remainingHours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const remainingMinutes = Math.floor(
    (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
  );

  return {
    days: remainingDays,
    hours: remainingHours,
    minutes: remainingMinutes,
  };
}

export function convertUTCtoLocal(utcDate) {
  // Create a new Date object with the UTC date
  var date = new Date(utcDate);

  // Get the timezone offset in minutes
  var offset = date.getTimezoneOffset();

  // Adjust the date by adding the offset in minutes
  date.setMinutes(date.getMinutes() - offset);

  // Return the adjusted date
  return date;
}

export function findMinMax(string) {
  // Split the string into an array of values
  const values = string.split(',');

  // Initialize min and max variables with the first value in the array
  let min = Number(values[0]);
  let max = Number(values[0]);

  // Iterate through the values and update min and max if necessary
  for (let i = 1; i < values.length; i++) {
    const currentValue = Number(values[i]);
    if (currentValue < min) {
      min = currentValue;
    }
    if (currentValue > max) {
      max = currentValue;
    }
  }
  // Return the min and max values as an object
  return { min, max };
}

export function nFormatter(num, digits) {
  const lookup = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' },
    { value: 1, symbol: '' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().find(function (item) {
    return num >= item.value;
  });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
}

export function calculateTimeRemaining(futureDate) {
  const currentTime = new Date();
  const timeDifference = futureDate - currentTime;

  if (timeDifference < 0) {
    return 'overdue';
  }

  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const daysRemaining = Math.floor(timeDifference / millisecondsPerDay);
  const hoursRemaining = Math.floor(
    (timeDifference % millisecondsPerDay) / (60 * 60 * 1000)
  );

  return `${daysRemaining} days, ${hoursRemaining} hours`;
}

export function timeAgo(created_ts) {
  if (Array.isArray(created_ts)) {
    // a bit of a silly check, but adding it to keep the method interface the same
    // for other use cases.
    const [year, month, day] = created_ts;
    // decrement the month because of the method interface expects an index, not a value:
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
    //     Integer value representing the month, beginning with 0 for January to 11 for December.
    // we don't have to worry about edge cases, because Date() handles that for us:
    //     If any parameter overflows its defined bounds, it "carries over".
    //     For example, if a monthIndex greater than 11 is passed in, those months will cause the year to increment
    return formatDistanceToNow(new Date(year, month - 1, day, 0, 0, 0), {
      includeSeconds: true,
    });
  }
  const today = new Date();
  const createdOn = new Date(created_ts);
  const msInDay = 24 * 60 * 60 * 1000;
  const msInMin = 60 * 1000;
  const msInHr = 60 * 60 * 1000;
  const diff = +today - +createdOn;
  const days = diff / msInDay;
  const mins = diff / msInMin;
  const hrs = diff / msInHr; // Math.round(days.toFixed(2)*24)
  if (days > 1) {
    return `${days.toFixed(0)}d`;
  }
  if (hrs > 1) {
    return `${Math.round(hrs.toFixed(2))}hr(s)`;
  }
  return `${Math.round(mins.toFixed(2))}mins`;
}
