import React from 'react';
import axios from 'axios';
import Config from '../shared/Config';
import { handlerApiError } from '../shared/errorHandler';

const { apiBaseUrl } = Config();
const endpointUrl = `${apiBaseUrl}/findings`;
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const cache = {};

export default function useFinding(id) {
  console.log(`useFinding called for id: ${id}`);
  const access_token = window.sessionStorage.getItem('token');
  headers.Authorization = `Bearer ${access_token}`;
  const [loading, setLoading] = React.useState(true);
  const [finding, setFinding] = React.useState({});

  const getFinding = (config, id) => {
    config.url = `${endpointUrl}/${id}`;
    axios(config)
      .then((response) => {
        const { data } = response;
        setFinding(data);
        cache[id] = data;
        setLoading(false);
      })
      .catch((err) => handlerApiError(err));
  };

  React.useEffect(() => {
    const config = { url: endpointUrl, headers };
    if (cache[id]) {
      setFinding(cache[id]);
      setLoading(false);
    } else {
      getFinding(config, id);
    }
  }, [id]);

  return { loading, finding };
}

export function setFindingStatus(status) {
  console.log(`setFindingStatus ${status} for id: ${id}`);
  const access_token = window.sessionStorage.getItem('token');
  headers.Authorization = `Bearer ${access_token}`;
  const [loading, setLoading] = React.useState(true);
  const [finding, setFinding] = React.useState({});

  const setStatus = (config, id, status) => {
    config.url = `${endpointUrl}/${id}`;
    axios
      .put(config.url, { status }, config)
      .then((response) => {
        const { data } = response;
        setFindingStatus(data);
        cache[id] = data;
        setLoading(false);
      })
      .catch((err) => handlerApiError(err));
  };

  React.useEffect(() => {
    const config = { url: endpointUrl, headers };
    if (cache[id]) {
      setFinding(cache[id]);
      setLoading(false);
    } else {
      getFinding(config, id);
    }
  }, [status]);

  return { loading, finding };
}
