import React from 'react';
import { Link } from 'react-router-dom';
import { Campaign } from '@mui/icons-material';
import {
  AppBar,
  Avatar,
  CardHeader,
  Tooltip,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useAuth } from 'oidc-react';
import AppDrawer from './AppDrawer';
import usePerson from '../hooks/usePerson';
import { isCampaignsAdmin } from '../shared/serviceHandler';
//import SearchBar from './SearchBar';

function AppNavigation() {
  const runEnv = process.env.REACT_APP_CONFIG;
  // const navigate = useNavigate();
  const auth = useAuth();
  const { email } = auth.userData.profile;
  const { person, loading } = usePerson(email);

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <IconButton
              onClick={() => setDrawerOpen(true)}
              size="large"
              edge="start"
              aria-label="menu"
            >
              <Campaign color="secondary" />
            </IconButton>
            <Typography
              variant="h6"
              color="secondary"
              component={Link}
              to="/"
              sx={{
                textDecoration: 'none',
                flexGrow: 1,
                display: { xs: 'none', sm: 'block' },
              }}
            >
              Puff Campaigns
              {runEnv === 'prod' ? null : ` (${runEnv.toUpperCase()})`}
            </Typography>
            {
              // TODO: figure out a way to enable searching for categories, teams, campaigns, etc.
              // <form noValidate autoComplete="off">
              // <SearchBar />
              //
              //< /form>
            }
            {loading ? null : (
              <span>
                <Typography
                  color="secondary"
                  component={Link}
                  to={`/person/${email.replace('@gopuff.com', '')}`}
                >
                  <Tooltip
                    title={`${person.full_name} ${
                      isCampaignsAdmin(auth) ? '(Admin)' : ''
                    }`}
                    sx={{ pr: 0 }}
                  >
                    <CardHeader
                      avatar={
                        <Avatar
                          alt={person.full_name}
                          src={person.slack_photo}
                          sx={{
                            width: 30,
                            height: 30,
                            border: isCampaignsAdmin(auth)
                              ? '2px solid white'
                              : 'none',
                          }}
                        />
                      }
                    />
                  </Tooltip>
                </Typography>
              </span>
            )}
          </Toolbar>
        </AppBar>
      </Box>
      <AppDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
    </>
  );
}

export default AppNavigation;
